import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "p-fluid" };
const _hoisted_2 = { class: "p-field" };
const _hoisted_3 = { class: "p-field" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_p_button = _resolveComponent("p-button");
    const _component_p_dialog = _resolveComponent("p-dialog");
    return (_openBlock(), _createBlock(_component_p_dialog, {
        header: "Service Form",
        visible: _ctx.visible,
        "onUpdate:visible": _cache[2] || (_cache[2] = ($event) => ((_ctx.visible) = $event)),
        modal: true,
        closable: true,
        onHide: _ctx.cancel
    }, {
        footer: _withCtx(() => [
            _createVNode(_component_p_button, {
                label: "Save",
                icon: "pi pi-check",
                onClick: _ctx.save
            }, null, 8, ["onClick"]),
            _createVNode(_component_p_button, {
                label: "Cancel",
                icon: "pi pi-times",
                class: "p-button-text",
                onClick: _ctx.cancel
            }, null, 8, ["onClick"])
        ]),
        default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _cache[3] || (_cache[3] = _createElementVNode("label", { for: "name" }, "Name", -1)),
                    _withDirectives(_createElementVNode("input", {
                        id: "name",
                        type: "text",
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.localService.name) = $event)),
                        class: "p-inputtext p-component"
                    }, null, 512), [
                        [_vModelText, _ctx.localService.name]
                    ])
                ]),
                _createElementVNode("div", _hoisted_3, [
                    _cache[4] || (_cache[4] = _createElementVNode("label", { for: "duration" }, "Duration", -1)),
                    _withDirectives(_createElementVNode("input", {
                        id: "duration",
                        type: "text",
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.localService.duration) = $event)),
                        class: "p-inputtext p-component"
                    }, null, 512), [
                        [_vModelText, _ctx.localService.duration]
                    ])
                ])
            ])
        ]),
        _: 1
    }, 8, ["visible", "onHide"]));
}
