import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "calendar-header" };
const _hoisted_2 = { class: "navigation_container" };
const _hoisted_3 = { class: "col-3" };
const _hoisted_4 = { class: "days" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_p_button = _resolveComponent("p-button");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_p_button, {
                class: "col-3",
                severity: "secondary",
                onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('previous-week')))
            }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode(" Previous Week ")
                ])),
                _: 1
            }),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.formattedWeekRange), 1),
            _createVNode(_component_p_button, {
                class: "col-3",
                severity: "secondary",
                onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('next-week')))
            }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode(" Next Week ")
                ])),
                _: 1
            })
        ]),
        _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.weekRange, (day) => {
                return (_openBlock(), _createElementBlock("div", {
                    key: day.date,
                    class: "day"
                }, _toDisplayString(day.label), 1));
            }), 128))
        ])
    ]));
}
