import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = ["src"];
const _hoisted_2 = { key: 0 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_p_button = _resolveComponent("p-button");
    const _component_router_view = _resolveComponent("router-view");
    return (_openBlock(), _createElementBlock("div", null, [
        _createVNode(_component_p_button, {
            label: "Logout",
            class: "p-button-danger",
            onClick: _ctx.handleLogout
        }, null, 8, ["onClick"]),
        _createElementVNode("img", {
            class: "logo",
            alt: "Chloe's Beaute logo",
            src: _ctx.logo
        }, null, 8, _hoisted_1),
        (!_ctx.isHomePage)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_p_button, {
                    class: "mb-2",
                    label: "Back",
                    icon: "pi pi-arrow-left",
                    onClick: _ctx.goBack
                }, null, 8, ["onClick"])
            ]))
            : _createCommentVNode("", true),
        _createVNode(_component_p_button, {
            class: "m-2",
            label: "FaceRecognition",
            icon: "pi pi-user",
            onClick: _ctx.goToFaceRecognition
        }, null, 8, ["onClick"]),
        _createVNode(_component_p_button, {
            class: "m-2",
            label: "Customers",
            icon: "pi pi-user",
            onClick: _ctx.goToCustomers
        }, null, 8, ["onClick"]),
        _createVNode(_component_p_button, {
            class: "m-2",
            label: "Services",
            icon: "pi pi-user",
            onClick: _ctx.goToServices
        }, null, 8, ["onClick"]),
        _createVNode(_component_p_button, {
            class: "",
            label: "Calendar",
            icon: "pi pi-calendar",
            onClick: _ctx.goToCalendar
        }, null, 8, ["onClick"]),
        _createVNode(_component_router_view)
    ]));
}
