import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "calendar" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_CalendarHeader = _resolveComponent("CalendarHeader");
    const _component_CalendarGrid = _resolveComponent("CalendarGrid");
    const _component_AppointmentForm = _resolveComponent("AppointmentForm");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_CalendarHeader, {
            "week-range": _ctx.weekRange,
            onPreviousWeek: _ctx.goToPreviousWeek,
            onNextWeek: _ctx.goToNextWeek
        }, null, 8, ["week-range", "onPreviousWeek", "onNextWeek"]),
        _createVNode(_component_CalendarGrid, {
            "week-range": _ctx.weekRange,
            "start-hour": 9,
            "end-hour": 22,
            onOpenAppointmentForm: _ctx.openAppointmentForm,
            appointments: _ctx.appointments
        }, null, 8, ["week-range", "onOpenAppointmentForm", "appointments"]),
        (_ctx.showAppointmentForm)
            ? (_openBlock(), _createBlock(_component_AppointmentForm, {
                key: 0,
                appointment: _ctx.selectedAppointment,
                editingAppointment: _ctx.editingAppointment,
                day: _ctx.selectedDay,
                hour: _ctx.selectedHour,
                services: _ctx.services,
                customers: _ctx.customers,
                "can-add-appointment": true,
                onSave: _ctx.addAppointment,
                onEdit: _ctx.editAppointment,
                onCancel: _ctx.closeAppointmentForm,
                onDelete: _ctx.deleteAppointment
            }, null, 8, ["appointment", "editingAppointment", "day", "hour", "services", "customers", "onSave", "onEdit", "onCancel", "onDelete"]))
            : _createCommentVNode("", true)
    ]));
}
