import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "p-fluid" };
const _hoisted_2 = { class: "p-field" };
const _hoisted_3 = { class: "p-field" };
const _hoisted_4 = { class: "p-field" };
const _hoisted_5 = { class: "p-field" };
const _hoisted_6 = { class: "p-field" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_p_button = _resolveComponent("p-button");
    const _component_p_dialog = _resolveComponent("p-dialog");
    return (_openBlock(), _createBlock(_component_p_dialog, {
        header: "Customer Form",
        visible: _ctx.visible,
        "onUpdate:visible": _cache[5] || (_cache[5] = ($event) => ((_ctx.visible) = $event)),
        modal: true,
        closable: true,
        onHide: _ctx.cancel
    }, {
        footer: _withCtx(() => [
            _createVNode(_component_p_button, {
                label: "Save",
                icon: "pi pi-check",
                onClick: _ctx.save
            }, null, 8, ["onClick"]),
            _createVNode(_component_p_button, {
                label: "Cancel",
                icon: "pi pi-times",
                class: "p-button-text",
                onClick: _ctx.cancel
            }, null, 8, ["onClick"])
        ]),
        default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _cache[6] || (_cache[6] = _createElementVNode("label", { for: "friendly_name" }, "Friendly Name", -1)),
                    _withDirectives(_createElementVNode("input", {
                        id: "friendly_name",
                        type: "text",
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.localCustomer.friendly_name) = $event)),
                        class: "p-inputtext p-component"
                    }, null, 512), [
                        [_vModelText, _ctx.localCustomer.friendly_name]
                    ])
                ]),
                _createElementVNode("div", _hoisted_3, [
                    _cache[7] || (_cache[7] = _createElementVNode("label", { for: "first_name" }, "First Name", -1)),
                    _withDirectives(_createElementVNode("input", {
                        id: "first_name",
                        type: "text",
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.localCustomer.first_name) = $event)),
                        class: "p-inputtext p-component"
                    }, null, 512), [
                        [_vModelText, _ctx.localCustomer.first_name]
                    ])
                ]),
                _createElementVNode("div", _hoisted_4, [
                    _cache[8] || (_cache[8] = _createElementVNode("label", { for: "last_name" }, "Last Name", -1)),
                    _withDirectives(_createElementVNode("input", {
                        id: "last_name",
                        type: "text",
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.localCustomer.last_name) = $event)),
                        class: "p-inputtext p-component"
                    }, null, 512), [
                        [_vModelText, _ctx.localCustomer.last_name]
                    ])
                ]),
                _createElementVNode("div", _hoisted_5, [
                    _cache[9] || (_cache[9] = _createElementVNode("label", { for: "email_address" }, "Email", -1)),
                    _withDirectives(_createElementVNode("input", {
                        id: "email_address",
                        type: "email",
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => ((_ctx.localCustomer.email_address) = $event)),
                        class: "p-inputtext p-component"
                    }, null, 512), [
                        [_vModelText, _ctx.localCustomer.email_address]
                    ])
                ]),
                _createElementVNode("div", _hoisted_6, [
                    _cache[10] || (_cache[10] = _createElementVNode("label", { for: "mobile_number" }, "Phone", -1)),
                    _withDirectives(_createElementVNode("input", {
                        id: "mobile_number",
                        type: "text",
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => ((_ctx.localCustomer.mobile_number) = $event)),
                        class: "p-inputtext p-component"
                    }, null, 512), [
                        [_vModelText, _ctx.localCustomer.mobile_number]
                    ])
                ])
            ])
        ]),
        _: 1
    }, 8, ["visible", "onHide"]));
}
