const API_URL = process.env.VUE_APP_BACKEND_SERVER;
export async function loginUser(form) {
    try {
        const response = await fetch(`${API_URL}/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(form),
        });
        if (!response.ok) {
            throw new Error('Invalid username or password.');
        }
        const data = await response.json();
        return { success: true, token: data.token, name: data.name, id: data.id };
    }
    catch (error) {
        console.error('Error logging in', error);
        throw error;
    }
}
export async function loginWithFaceId(faceImage) {
    try {
        const response = await fetch(`${API_URL}/login/face`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ faceDescriptor: faceImage }),
        });
        if (!response.ok) {
            throw new Error('Invalid face id.');
        }
        const data = await response.json();
        return { success: true, token: data.token, name: data.name, id: data.id };
    }
    catch (error) {
        console.error('Error logging in with face id', error);
        throw error;
    }
}
export async function fetchAppointments(startDate, endDate) {
    try {
        const response = await fetch(`${API_URL}/appointment/range?startDate=` + startDate + '&endDate=' + endDate);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const transformedAppointments = data.map(appointment => ({
            ...appointment,
            service: appointment.service_name,
            hour: appointment.appointment_hour,
            minutes: appointment.appointment_minutes,
            date: appointment.appointment_date,
            // Include other properties if needed
        }));
        return transformedAppointments;
    }
    catch (error) {
        console.error('Error fetching appointments', error);
        throw error;
    }
}
export async function fetchServices() {
    try {
        const response = await fetch(`${API_URL}/service`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;
    }
    catch (error) {
        console.error('Error fetching services', error);
        throw error;
    }
}
export async function apiAddService(service) {
    try {
        const response = await fetch(`${API_URL}/service`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(service),
        });
        if (!response.ok) {
            throw new Error('Failed to save the service');
        }
        return { success: true };
    }
    catch (error) {
        console.error('Error saving the service:', error);
        return { success: false };
    }
}
export async function apiEditService(service) {
    try {
        const response = await fetch(`${API_URL}/service/` + service.id, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(service),
        });
        if (!response.ok) {
            throw new Error('Failed to update the service');
        }
        return { success: true };
    }
    catch (error) {
        console.error('Error updating the service:', error);
        return { success: false };
    }
}
export async function apiDeleteService(serviceId) {
    // Implement delete service
    try {
        const response = await fetch(`${API_URL}/service/` + serviceId, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return { success: true };
    }
    catch (error) {
        console.error('Error deleting service', error);
        throw error;
    }
}
export async function fetchCustomers() {
    try {
        const response = await fetch(`${API_URL}/customer`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;
    }
    catch (error) {
        console.error('Error fetching customers', error);
        throw error;
    }
}
export async function apiAddCustomer(customer) {
    try {
        const response = await fetch(`${API_URL}/customer`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(customer),
        });
        if (!response.ok) {
            throw new Error('Failed to save the customer');
        }
        return { success: true };
    }
    catch (error) {
        console.error('Error saving the customer:', error);
        return { success: false };
    }
}
export async function apiEditCustomer(customer) {
    try {
        const response = await fetch(`${API_URL}/customer/` + customer.id, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(customer),
        });
        if (!response.ok) {
            throw new Error('Failed to update the customer');
        }
    }
    catch (error) {
        console.error('Error updating the customer:', error);
    }
}
export async function apiDeleteCustomer(customerId) {
    try {
        const response = await fetch(`${API_URL}/customer/` + customerId, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
    }
    catch (error) {
        console.error('Error deleting customer', error);
        throw error;
    }
}
export async function apiAddAppointment(appointment) {
    try {
        const response = await fetch(`${API_URL}/appointment`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(appointment),
        });
        if (!response.ok) {
            throw new Error('Failed to save the appointment');
        }
        return { success: true };
    }
    catch (error) {
        console.error('Error saving the appointment:', error);
        return { success: false };
    }
}
export async function apiEditAppointment(appointment) {
    try {
        const response = await fetch(`${API_URL}/appointment/` + appointment.id, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(appointment),
        });
        if (!response.ok) {
            throw new Error('Failed to update the appointment');
        }
    }
    catch (error) {
        console.error('Error updating the appointment:', error);
    }
}
export async function apiDeleteAppointment(appointmentId) {
    try {
        const response = await fetch(`${API_URL}/appointment/` + appointmentId, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
    }
    catch (error) {
        console.error('Error deleting appointment', error);
        throw error;
    }
}
