import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "field" };
const _hoisted_2 = { class: "p-field p-fluid p-flex p-ai-center" };
const _hoisted_3 = { class: "p-field p-fluid p-flex p-ai-center" };
const _hoisted_4 = { class: "p-field p-fluid p-flex p-ai-center" };
const _hoisted_5 = { class: "p-field p-fluid p-flex p-ai-center" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_p_button = _resolveComponent("p-button");
    const _component_p_select = _resolveComponent("p-select");
    const _component_p_date_picker = _resolveComponent("p-date-picker");
    const _component_p_dialog = _resolveComponent("p-dialog");
    return (_openBlock(), _createBlock(_component_p_dialog, {
        visible: _ctx.showDialog,
        "onUpdate:visible": _cache[4] || (_cache[4] = ($event) => ((_ctx.showDialog) = $event)),
        header: "Add Appointment",
        modal: true,
        closable: true,
        onHide: _ctx.cancelEditing
    }, _createSlots({
        default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _cache[5] || (_cache[5] = _createElementVNode("label", { for: "customer" }, "Customer", -1)),
                    _createVNode(_component_p_select, {
                        id: "customer",
                        modelValue: _ctx.localAppointment.customer_id,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.localAppointment.customer_id) = $event)),
                        options: _ctx.customers,
                        "option-label": "friendly_name",
                        placeholder: "Select a customer",
                        "option-value": "id"
                    }, null, 8, ["modelValue", "options"])
                ]),
                _createElementVNode("div", _hoisted_3, [
                    _cache[6] || (_cache[6] = _createElementVNode("label", { for: "service" }, "Service", -1)),
                    _createVNode(_component_p_select, {
                        id: "service",
                        modelValue: _ctx.localAppointment.service_id,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.localAppointment.service_id) = $event)),
                        options: _ctx.services,
                        "option-label": "name",
                        placeholder: "Select a service",
                        "option-value": "id"
                    }, null, 8, ["modelValue", "options"])
                ]),
                _createElementVNode("div", _hoisted_4, [
                    _cache[7] || (_cache[7] = _createElementVNode("label", { for: "service-duration" }, "Duration", -1)),
                    _createVNode(_component_p_select, {
                        id: "service-duration",
                        modelValue: _ctx.localAppointment.duration,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.localAppointment.duration) = $event)),
                        options: _ctx.durationOptions,
                        placeholder: "Select a duration"
                    }, null, 8, ["modelValue", "options"])
                ]),
                _createElementVNode("div", _hoisted_5, [
                    _cache[8] || (_cache[8] = _createElementVNode("label", { for: "service-start" }, "Time", -1)),
                    _createVNode(_component_p_date_picker, {
                        id: "service-start",
                        modelValue: _ctx.time,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => ((_ctx.time) = $event)),
                        "time-only": "",
                        fluid: "",
                        "step-minute": 15
                    }, null, 8, ["modelValue"])
                ])
            ])
        ]),
        _: 2
    }, [
        (_ctx.editingAppointment)
            ? {
                name: "footer",
                fn: _withCtx(() => [
                    _createVNode(_component_p_button, {
                        label: "Save",
                        icon: "pi pi-check",
                        disabled: !_ctx.canAddAppointment,
                        onClick: _ctx.handleEditAppointment
                    }, null, 8, ["disabled", "onClick"]),
                    _createVNode(_component_p_button, {
                        label: "Cancel",
                        icon: "pi pi-times",
                        class: "p-button-text",
                        onClick: _ctx.cancelEditing
                    }, null, 8, ["onClick"]),
                    _createVNode(_component_p_button, {
                        label: "Delete",
                        icon: "pi pi-trash",
                        severity: "danger",
                        class: "p-button-text",
                        onClick: _ctx.handleDeleteAppointment
                    }, null, 8, ["onClick"])
                ]),
                key: "0"
            }
            : {
                name: "footer",
                fn: _withCtx(() => [
                    _createVNode(_component_p_button, {
                        label: "Add",
                        icon: "pi pi-check",
                        disabled: !_ctx.canAddAppointment,
                        onClick: _ctx.handleAddAppointment
                    }, null, 8, ["disabled", "onClick"]),
                    _createVNode(_component_p_button, {
                        label: "Cancel",
                        icon: "pi pi-times",
                        class: "p-button-text",
                        onClick: _ctx.cancelEditing
                    }, null, 8, ["onClick"])
                ]),
                key: "1"
            }
    ]), 1032, ["visible", "onHide"]));
}
