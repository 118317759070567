// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura';
import router from './router';
import 'primeflex/primeflex.css'; // PrimeFlex for flexbox utilities
import './custom-primeflex.css';
import 'primeicons/primeicons.css'; // PrimeIcons for icons
import 'primeflex/primeflex.css'; // PrimeFlex for flexbox utilities
import './assets/styles.css'; // Custom styles
import DatePicker from 'primevue/datepicker';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Calendar from 'primevue/calendar';
import Select from 'primevue/select';
import InputText from 'primevue/inputtext';
import InputIcon from 'primevue/inputicon';
import IconField from 'primevue/iconfield';
import Column from 'primevue/column';
import { DataTable } from 'primevue';
// import { listenForMessages } from "./firebase/firebase-helper";
createApp(App)
    // .use(router)
    .use(PrimeVue, {
    theme: {
        //     preset: 'saga-blue'
        preset: Aura,
        primary: '#000',
        semantic: {
            primary: '#000',
        },
        options: {
            prefix: 'p',
            darkModeSelector: 'system',
            cssLayer: false,
        }
    }
})
    .component('p-date-picker', DatePicker)
    .component('p-button', Button)
    .component('p-dialog', Dialog)
    .component('p-calendar', Calendar)
    .component('p-select', Select)
    .component('p-inputtext', InputText)
    .component('p-inputicon', InputIcon)
    .component('p-iconfield', IconField)
    .component('p-column', Column)
    .component('p-data-table', DataTable)
    .use(router)
    .mount('#app');
// listenForMessages();
