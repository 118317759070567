export function calculateOverlappingAppointments(appointments) {
    console.log('__________________________________________Calculating overlapping appointments...');
    // Parse appointments to calculate start and end times in minutes
    const parsedAppointments = appointments.map((appt) => {
        const start = parseInt(appt.hour) * 60 + parseInt(appt.minutes);
        const end = start + appt.duration;
        // console.log(`ID: ${appt.id}, Date: ${appt.date}, Start: ${start}, End: ${end}`);
        return { ...appt, start, end, width: 0, offset: 0 };
    });
    // Group appointments by date
    const eventsByDate = parsedAppointments.reduce((acc, appt) => {
        if (!acc[appt.date]) {
            acc[appt.date] = [];
        }
        acc[appt.date].push(appt);
        return acc;
    }, {});
    // Process each date group
    Object.keys(eventsByDate).forEach((date) => {
        const events = eventsByDate[date];
        // Sort events by start time
        events.sort((a, b) => a.start - b.start);
        // Array to track columns of overlapping appointments
        const columns = [];
        events.forEach((event) => {
            let placed = false;
            // Try to place the event in an existing column
            for (const column of columns) {
                const lastEventInColumn = column[column.length - 1];
                if (lastEventInColumn.end > event.start) {
                    column.push(event);
                    placed = true;
                    break;
                }
            }
            // If not placed, create a new column
            if (!placed) {
                columns.push([event]);
            }
        });
        // Assign width and offset to each event
        columns.forEach((column) => {
            const totalColumns = column.length;
            const width = 100 / totalColumns - (5 / totalColumns);
            column.forEach((event, colIndex) => {
                event.width = width;
                event.offset = colIndex * width;
            });
        });
    });
    return parsedAppointments;
}
