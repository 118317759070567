import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "calendar-grid" };
const _hoisted_2 = { class: "row header-row" };
const _hoisted_3 = {
    key: 0,
    class: "cell time-cell"
};
const _hoisted_4 = {
    key: 1,
    class: "cell time-cell"
};
const _hoisted_5 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_AppointmentItem = _resolveComponent("AppointmentItem");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "cell time-cell" }, null, -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.workingDays, (day, index) => {
                return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: "cell day-header"
                }, _toDisplayString(_ctx.format(day, "EEEE")), 1));
            }), 128))
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.timeSlots, (hour, hourIndex) => {
            return (_openBlock(), _createElementBlock("div", {
                key: hourIndex,
                class: "row time-row"
            }, [
                (hourIndex % 2 === 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.format(hour, "HH:mm")), 1))
                    : (_openBlock(), _createElementBlock("div", _hoisted_4)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.workingDays, (day) => {
                    return (_openBlock(), _createElementBlock("div", {
                        key: day + ' ' + hourIndex,
                        class: "cell day-cell",
                        onClick: ($event) => (_ctx.handleCellClick($event, day, hour))
                    }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getAppointmentsForDayAndHour(day, hour), (appointment) => {
                            return (_openBlock(), _createBlock(_component_AppointmentItem, {
                                key: appointment.id,
                                appointment: appointment,
                                onClick: ($event) => (_ctx.editAppointment($event, appointment))
                            }, null, 8, ["appointment", "onClick"]));
                        }), 128))
                    ], 8, _hoisted_5));
                }), 128))
            ]));
        }), 128))
    ]));
}
