import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx } from "vue";
const _hoisted_1 = { class: "login-container" };
const _hoisted_2 = {
    key: 0,
    class: "error"
};
const _hoisted_3 = { class: "form-group" };
const _hoisted_4 = { class: "form-group" };
const _hoisted_5 = { key: 2 };
const _hoisted_6 = {
    ref: "video",
    autoplay: "",
    muted: "",
    playsinline: ""
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_p_button = _resolveComponent("p-button");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _cache[7] || (_cache[7] = _createElementVNode("h1", null, "Login", -1)),
        (_ctx.errorMessage)
            ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.errorMessage), 1))
            : _createCommentVNode("", true),
        (!_ctx.useFaceId)
            ? (_openBlock(), _createElementBlock("form", {
                key: 1,
                onSubmit: _cache[2] || (_cache[2] = _withModifiers(
                //@ts-ignore
                (...args) => (_ctx.handleLogin && _ctx.handleLogin(...args)), ["prevent"]))
            }, [
                _createElementVNode("div", _hoisted_3, [
                    _cache[3] || (_cache[3] = _createElementVNode("label", { for: "username" }, "Username:", -1)),
                    _withDirectives(_createElementVNode("input", {
                        id: "username",
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.form.username) = $event)),
                        type: "text",
                        required: ""
                    }, null, 512), [
                        [_vModelText, _ctx.form.username]
                    ])
                ]),
                _createElementVNode("div", _hoisted_4, [
                    _cache[4] || (_cache[4] = _createElementVNode("label", { for: "password" }, "Password:", -1)),
                    _withDirectives(_createElementVNode("input", {
                        id: "password",
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.form.password) = $event)),
                        type: "password",
                        required: ""
                    }, null, 512), [
                        [_vModelText, _ctx.form.password]
                    ])
                ]),
                _createVNode(_component_p_button, {
                    label: "Login",
                    type: "submit"
                })
            ], 32))
            : _createCommentVNode("", true),
        (_ctx.useFaceId)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _cache[5] || (_cache[5] = _createElementVNode("p", null, "Look at the camera to login.", -1)),
                _createElementVNode("video", _hoisted_6, null, 512),
                _createVNode(_component_p_button, {
                    label: "Capture Face",
                    onClick: _ctx.captureFace
                }, null, 8, ["onClick"])
            ]))
            : _createCommentVNode("", true),
        _createVNode(_component_p_button, {
            label: "Login with Face ID",
            onClick: _ctx.toggleLoginMethod
        }, {
            icon: _withCtx(() => _cache[6] || (_cache[6] = [
                _createElementVNode("i", { class: "pi pi-camera" }, null, -1)
            ])),
            _: 1
        }, 8, ["onClick"])
    ]));
}
