import { createRouter, createWebHistory } from 'vue-router';
import LayoutPage from './components/LayoutPage.vue';
import LoginPage from './components/LoginPage.vue';
import CalendarPage from './components/calendar/CalendarPage.vue';
import CustomerPage from './components/customer/CustomerPage.vue';
import ServicePage from './components/service/ServicePage.vue';
const routes = [
    {
        path: '/login',
        name: 'LoginPage',
        component: LoginPage,
        meta: { requiresAuth: false }
    },
    {
        path: '/',
        // name: 'HomePage',
        component: LayoutPage,
        meta: { requiresAuth: true },
        children: [
            {
                path: 'calendar',
                name: 'CalendarPage',
                component: CalendarPage,
                meta: { requiresAuth: true }
            },
            {
                path: 'services',
                name: 'ServicePage',
                component: ServicePage,
                meta: { requiresAuth: true }
            },
            {
                path: 'customers',
                name: 'CustomerPage',
                component: CustomerPage,
                meta: { requiresAuth: true }
            }
        ],
    },
];
const router = createRouter({
    history: createWebHistory(),
    routes
});
router.beforeEach((to, from, next) => {
    const isAuthenticated = !!localStorage.getItem('token');
    if (to.meta.requiresAuth && !isAuthenticated) {
        next('/login'); // Redirect to login if not authenticated
    }
    else {
        next();
    }
});
export default router;
