import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "flex justify-between" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_p_button = _resolveComponent("p-button");
    const _component_p_inputicon = _resolveComponent("p-inputicon");
    const _component_p_inputtext = _resolveComponent("p-inputtext");
    const _component_p_iconfield = _resolveComponent("p-iconfield");
    const _component_p_column = _resolveComponent("p-column");
    const _component_p_data_table = _resolveComponent("p-data-table");
    const _component_CustomerForm = _resolveComponent("CustomerForm");
    return (_openBlock(), _createElementBlock("div", null, [
        _cache[6] || (_cache[6] = _createElementVNode("h1", null, "Customer Listing", -1)),
        _createVNode(_component_p_button, {
            label: "Add Customer",
            icon: "pi pi-plus",
            onClick: _ctx.openNewCustomerDialog
        }, null, 8, ["onClick"]),
        _createVNode(_component_p_data_table, {
            value: _ctx.customers,
            filters: _ctx.filters,
            "onUpdate:filters": _cache[2] || (_cache[2] = ($event) => ((_ctx.filters) = $event)),
            paginator: "",
            showGridlines: "",
            rows: 10,
            dataKey: "id",
            responsiveLayout: "scroll"
        }, {
            header: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_p_button, {
                        type: "button",
                        icon: "pi pi-filter-slash",
                        label: "Clear",
                        outlined: "",
                        onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.clearFilter()))
                    }),
                    _createVNode(_component_p_iconfield, { class: "mx-3" }, {
                        default: _withCtx(() => [
                            _createVNode(_component_p_inputicon, null, {
                                default: _withCtx(() => _cache[3] || (_cache[3] = [
                                    _createElementVNode("i", { class: "pi pi-search" }, null, -1)
                                ])),
                                _: 1
                            }),
                            _createVNode(_component_p_inputtext, {
                                modelValue: _ctx.filters['global'].value,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.filters['global'].value) = $event)),
                                placeholder: "Keyword Search"
                            }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                    })
                ])
            ]),
            empty: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode(" No customers found. ")
            ])),
            loading: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode(" Loading customers data. Please wait. ")
            ])),
            default: _withCtx(() => [
                _createVNode(_component_p_column, {
                    field: "friendly_name",
                    header: "Name"
                }, {
                    body: _withCtx(({ data }) => [
                        _createTextVNode(_toDisplayString(data.friendly_name), 1)
                    ]),
                    filter: _withCtx(({ filterModel }) => [
                        _createVNode(_component_p_inputtext, {
                            modelValue: filterModel.value,
                            "onUpdate:modelValue": ($event) => ((filterModel.value) = $event),
                            type: "text",
                            placeholder: "Search by name"
                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 1
                }),
                _createVNode(_component_p_column, {
                    field: "first_name",
                    header: "First Name"
                }),
                _createVNode(_component_p_column, {
                    field: "last_name",
                    header: "Last Name"
                }),
                _createVNode(_component_p_column, {
                    field: "email_address",
                    header: "Email"
                }),
                _createVNode(_component_p_column, {
                    field: "mobile_number",
                    header: "Phone"
                }),
                _createVNode(_component_p_column, { header: "Actions" }, {
                    body: _withCtx((slotProps) => [
                        _createVNode(_component_p_button, {
                            icon: "pi pi-pencil",
                            class: "mx-3",
                            onClick: ($event) => (_ctx.editCustomer(slotProps.data))
                        }, null, 8, ["onClick"]),
                        _createVNode(_component_p_button, {
                            icon: "pi pi-trash",
                            class: "p-button-danger",
                            onClick: ($event) => (_ctx.deleteCustomer(slotProps.data))
                        }, null, 8, ["onClick"])
                    ]),
                    _: 1
                })
            ]),
            _: 1
        }, 8, ["value", "filters"]),
        (_ctx.showCustomerForm)
            ? (_openBlock(), _createBlock(_component_CustomerForm, {
                key: 0,
                customer: _ctx.selectedCustomer,
                onSave: _ctx.saveCustomer,
                onCancel: _ctx.closeCustomerForm
            }, null, 8, ["customer", "onSave", "onCancel"]))
            : _createCommentVNode("", true)
    ]));
}
